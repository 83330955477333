import { validate, object, string, type Infer } from 'superstruct'

declare global {
  interface Window { wasAfterClick?: boolean }
}

const privateSchema = object({
  AWS_ACCESS_KEY_ID_GF: string(),
  AWS_SECRET_ACCESS_KEY_GF: string(),
  AWS_REGION_GF: string(),
  TABLE_NAME: string(),
  BUCKET_NAME: string(),
  REVALIDATE_KEY: string(),
  NOTION_API_KEY: string(),
  NOTION_DATABASE_ID: string(),
  NOTION_TOPICS_DATABASE_ID: string(),
  OPENAI_KEY: string(),
  CLERK_SECRET_KEY: string(),
  AMIGA_API_SECRET: string(),
  HUBSPOT_API_KEY: string(),
  HUBSPOT_LIST_ID: string(),
  NOTION_ACTIVE_USER: string(),
  NOTION_TOKEN_V2: string(),
  SUMMARIZE_URL: string(),
  SLACK_MONITORING_CHANNEL_ID: string(),
  SLACK_BOT_TOKEN: string(),
  TYPESENSE_HOST: string(),
  TYPESENSE_API_KEY: string(),
  SUMMARIZE_API_ENDPOINT: string(),
})

const publicSchema = object({
  NEXT_PUBLIC_ALGOLIA_APP_ID: string(),
  NEXT_PUBLIC_ALGOLIA_API_KEY: string(),
  NEXT_PUBLIC_ALGOLIA_INDEX: string(),
  NEXT_PUBLIC_ALGOLIA_SUGGESTIONS_INDEX: string(),
  NEXT_PUBLIC_BUCKET_NAME: string(),
  NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: string(),
  NEXT_PUBLIC_CLERK_SIGN_IN_URL: string(),
  NEXT_PUBLIC_CLERK_SIGN_UP_URL: string(),
  NEXT_PUBLIC_AMIGA_API_ENDPOINT: string(),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF: string(),
  NEXT_PUBLIC_VERCEL_ENV: string(),
  NEXT_PUBLIC_VERCEL_BRANCH_URL: string(),
  NEXT_PUBLIC_VERCEL_URL: string(),
})

type privateType = Infer<typeof privateSchema>

const publicenvVariables = {
  NEXT_PUBLIC_ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  NEXT_PUBLIC_ALGOLIA_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  NEXT_PUBLIC_ALGOLIA_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_INDEX,
  NEXT_PUBLIC_ALGOLIA_SUGGESTIONS_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_SUGGESTIONS_INDEX,
  NEXT_PUBLIC_BUCKET_NAME: process.env.NEXT_PUBLIC_BUCKET_NAME,
  NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
  NEXT_PUBLIC_CLERK_SIGN_IN_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_IN_URL,
  NEXT_PUBLIC_CLERK_SIGN_UP_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_UP_URL,
  NEXT_PUBLIC_AMIGA_API_ENDPOINT: process.env.NEXT_PUBLIC_AMIGA_API_ENDPOINT,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_BRANCH_URL: process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
}

const privateEnvVariables = {
  AWS_ACCESS_KEY_ID_GF: process.env.AWS_ACCESS_KEY_ID_GF,
  AWS_SECRET_ACCESS_KEY_GF: process.env.AWS_SECRET_ACCESS_KEY_GF,
  AWS_REGION_GF: process.env.AWS_REGION_GF,
  TABLE_NAME: process.env.TABLE_NAME,
  BUCKET_NAME: process.env.BUCKET_NAME,
  REVALIDATE_KEY: process.env.REVALIDATE_KEY,
  NOTION_API_KEY: process.env.NOTION_API_KEY,
  NOTION_DATABASE_ID: process.env.NOTION_DATABASE_ID,
  NOTION_TOPICS_DATABASE_ID: process.env.NOTION_TOPICS_DATABASE_ID,
  OPENAI_KEY: process.env.OPENAI_KEY,
  CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
  AMIGA_API_SECRET: process.env.AMIGA_API_SECRET,
  HUBSPOT_API_KEY: process.env.HUBSPOT_API_KEY,
  HUBSPOT_LIST_ID: process.env.HUBSPOT_LIST_ID,
  NOTION_ACTIVE_USER: process.env.NOTION_ACTIVE_USER,
  NOTION_TOKEN_V2: process.env.NOTION_TOKEN_V2,
  SUMMARIZE_URL: process.env.SUMMARIZE_URL,
  SLACK_MONITORING_CHANNEL_ID: process.env.SLACK_MONITORING_CHANNEL_ID,
  SLACK_BOT_TOKEN: process.env.SLACK_BOT_TOKEN,
  TYPESENSE_HOST: process.env.TYPESENSE_HOST,
  TYPESENSE_API_KEY: process.env.TYPESENSE_API_KEY,
  SUMMARIZE_API_ENDPOINT: process.env.SUMMARIZE_API_ENDPOINT,
}

const [errPublic, envPublic] = validate(publicenvVariables, publicSchema)
if (errPublic !== undefined) {
  console.error(errPublic)
  throw Error(`Public env var missing: ${errPublic.message}`)
}
const [errPrivate, envPrivate] = validate(privateEnvVariables, privateSchema)
if (envPrivate === undefined && typeof window === 'undefined') {
  console.error(errPrivate)
  throw Error(`Private env var missing: ${errPrivate.message}`)
}
const env = { ...envPublic, ...envPrivate as privateType }
export { env }
