export interface IPath {
  type: string
  name: string
  description?: string
  count?: number
  /** Whether or not the path should be hidden in pages, e.g. /judikatur/bvwg */
  hidden?: boolean
}
export type IPaths = Record<string, IPath>
export type IMapping = Record<string, {
  name: string
  description?: string
  paths: IPaths
  documentType: string[]
}>

// Descriptions based on feedback from Marco and Michi, see https://www.notion.so/gesetzefindenat/Enhancement-Improve-explain-text-for-E-R-Judikatur-Justiz-1bfadbc8c180450190752081455689eb
export const pathMappings = {
  bundesrecht: {
    name: 'Bundesrecht',
    description: 'Ins Bundesrecht fallen Gesetze, Verordnungen, Kundmachungen und Staatsverträge, die allgemein in ganz Österreich gelten. Dies unterscheidet es vom Landesrecht, welches nur für das jeweilige Bundesland gilt.',
    documentType: ['law'], // in future documentType
    paths: {
      bundesverfassungsgesetze: {
        type: 'BVG', // in future lawCategory
        name: 'Bundesverfassungsgesetze',
        description: 'Von mindestens 2/3 der anwesenden Parlamentsabgeordneten beschlossene Gesetze, wobei mindestens 1/2 aller Abgeordneten anwesend sein müssen.',
      },
      bundesgesetze: {
        type: 'BG',
        name: 'Bundesgesetze',
        description: 'Von 50% + 1 Stimme der anwesenden Parlamentsabgeordneten beschlossene Gesetze, wobei mindestens 1/3 aller Abgeordneten anwesend sein müssen.',
      },
      verordnungen: {
        type: 'V',
        name: 'Verordnungen',
        description: 'Eine von einer Verwaltungsbehörde, Bundesregierung oder einem Bundesminister erlassene allgemeine Rechtsnorm, die gesetzesgleiche Wirkung hat.',
      },
      vertraege: {
        type: 'Vertrag',
        name: 'Internationale Verträge',
        description: 'Internationale Verträge mit Staaten und/oder internationalen Organisationen.',
      },
      kundmachungen: {
        type: 'K',
        name: 'Kundmachungen',
        description: 'Kundmachungen zu Bundesgesetzen, Staatsverträgen und Verordnungen.',
      },
      vereinbarungen: {
        type: 'Vereinbarung',
        name: 'Art. 15a Vereinbarungen',
        description: 'Vereinbarung zwischen dem Bund und den Bundesländern (gemäß Art. 15a B-VG).',
      },
      geschaeftsordnungen: {
        type: 'Geschäftsordnung',
        name: 'Geschäftsordnungen',
        description: 'Regelungen von organisatorischen Abläufen und von Rechten/Pflichten der Abgeordneten.',
      },
      entschliessungen: {
        type: 'Entschließung',
        name: 'Entschließungen des Bundespräsidenten',
        description: 'Rechtsakte des Bundespräsidenten (zB. die Ernennung der Bundesregierung).',
      },
      bekanntmachungen: {
        type: 'Bekanntmachung',
        name: 'Bekanntmachungen',
        description: '',
      },
      verfuegungen: {
        type: 'Verfügung',
        name: 'Verfügungen',
        description: '',
      },
    },
  },
  judikatur: {
    name: 'Judikatur',
    description: 'Der Bereich Judikatur beinhaltet Entscheidungen und Rechtssätze von verschiedenen Gerichten und Behörden.',
    documentType: ['rechtssatz', 'decision'],
    paths: {
      justiz: {
        name: 'Justiz',
        type: 'Justiz', // in future category?
        description: 'Entscheidungen und Rechtssätze von folgenden Gerichten: Oberster Gerichtshof (OGH), Oberlandesgerichte (OLG), Landesgerichte (LG), Bezirksgerichte (BG), Oberster Patent- und Markensenat (OPMS bis Ende 2013) sowie ausländische Entscheidungen (AUSL).',
        hidden: true,
      },
      bvwg: {
        name: 'BVwG',
        type: 'BVwG',
        count: 3860265,
      },
      ogh: {
        name: 'OGH',
        type: 'OGH',
        count: 74658,
      },
      vwgh: {
        name: 'VwGH',
        type: 'VwGH',
        count: 68679,
      },
      vfgh: {
        name: 'VfGH',
        type: 'VfGH',
        count: 19601,
      },
      'olg-wien': {
        name: 'OLG Wien',
        type: 'OLG Wien',
        count: 8545,
      },
      'olg-graz': {
        name: 'OLG Graz',
        type: 'OLG Graz',
        count: 2747,
      },
      'olg-innsbruck': {
        name: 'OLG Innsbruck',
        type: 'OLG Innsbruck',
        count: 1419,
      },
      dsb: {
        name: 'DSB',
        type: 'DSB',
        count: 696,
      },
      'olg-linz': {
        name: 'OLG Linz',
        type: 'OLG Linz',
        count: 530,
      },
      'lg-fur-zrs-graz': {
        name: 'LG für ZRS Graz',
        type: 'LG für ZRS Graz',
        count: 140,
      },
      bfg: {
        name: 'BFG',
        type: 'BFG',
        hidden: true,
      },
      'lg-korneuburg': {
        name: 'LG Korneuburg',
        type: 'LG Korneuburg',
        count: 106,
      },
      'handelsgericht-wien': {
        name: 'Handelsgericht Wien',
        type: 'Handelsgericht Wien',
        count: 95,
      },
      'ausl-egmr': {
        name: 'AUSL EGMR',
        type: 'AUSL EGMR',
        count: 73,
      },
      'lg-wels': {
        name: 'LG Wels',
        type: 'LG Wels',
        count: 41,
      },
      'ausl-ekmr': {
        name: 'AUSL EKMR',
        type: 'AUSL EKMR',
        count: 10,
      },
      'ausl-bgh': {
        name: 'AUSL BGH',
        type: 'AUSL BGH',
        count: 3,
      },
    },
  },
  // landesrecht: {},
  // gemeinderecht: {}
} satisfies IMapping

export const typeMappings = {}

Object
  .entries(pathMappings)
  .forEach(([key, item]) => {
    Object
      .entries(item?.paths)
      .forEach(([_key, _item]) => {
        // Lowercase the key to work around inconsistent casing in the data
        // See https://www.notion.so/gesetzefindenat/Bug-auf-prod-crash-in-search-5a698f5ae20f4e14918da779a534f993?pvs=4
        typeMappings[_item.type.toLowerCase()] = {
          ..._item,
          category: key,
          subCategory: _key,
        }
      })
  })

const words = {
  bundesgesetze: 'Bundesgesetz',
  bundesverfassungsgesetze: 'Verfassungsgesetz',
  verordnungen: 'Verordnung',
  verträge: 'Vertrag',
  kundmachungen: 'Kundmachung',
  vereinbarungen: 'Vereinbarung',
  geschäftsordnungen: 'Geschäftsordnung',
  entschließungen: 'Entschließung',
  entscheidungen: 'Entscheidung',
  rechtssätze: 'Rechtssatz',
}
export function singular (text: string) {
  return words[text.toLowerCase()] as string
}

export const descriptionMappings = {
  gesetze: {
    10011336: 'Die Straßenverkehrsordnung (STVO) von 1960 ist ein österreichisches Bundesgesetz, das die Regeln und Vorschriften für den Straßenverkehr in Österreich festlegt. Das Gesetz regelt Aspekte wie Verkehrsregeln, Verkehrszeichen, Vorfahrtsregeln, Geschwindigkeitsbegrenzungen und Verhaltensweisen im Straßenverkehr.',
    10002296: 'Das Strafgesetzbuch (StGB) ist ein österreichisches Bundesgesetz, das strafbare Handlungen definiert und die Strafen dafür festlegt. Es regelt Verbrechen wie Diebstahl, Körperverletzung, Mord und Betrug. Das StGB bildet die rechtliche Grundlage für die strafrechtliche Verfolgung von Straftaten.',
    10001622: 'Das Allgemeine Bürgerliche Gesetzbuch (ABGB) ist ein österreichisches Bundesgesetz, das das Zivilrecht regelt. Es umfasst Bestimmungen zu Verträgen, Eigentum, Erbrecht, Familienrecht und Schadenersatz. Das ABGB bildet die rechtliche Grundlage für das Zusammenleben und den Rechtsverkehr.',
    10011040: 'Das Suchtmittelgesetz (SMG) ist ein österreichisches Bundesgesetz, das den Umgang mit Suchtmitteln wie Drogen regelt. Es verbietet den Besitz, Handel und Konsum von illegalen Substanzen und legt Maßnahmen zur Suchtprävention und -bekämpfung fest. Das SMG hat das Ziel, die öffentliche Gesundheit zu schützen und den Missbrauch von Suchtmitteln einzudämmen.',
    10002531: 'Das Mietrechtsgesetz (MRG) ist ein österreichisches Bundesgesetz, das die Rechte und Pflichten von Mietern und Vermietern regelt. Es betrifft Mietverträge, Mietzins, Kündigungsschutz und Instandhaltung von Mietobjekten. Das MRG zielt darauf ab, den Mieterschutz zu gewährleisten und ein faires Mietverhältnis sicherzustellen.',
    10001700: 'Die Exekutionsordnung (EO) ist ein österreichisches Gesetz, das die Regelungen für die Zwangsvollstreckung von Geldforderungen und anderen Vollstreckungstiteln festlegt. Sie regelt Verfahren wie Pfändungen und Zwangsversteigerungen. Die EO dient dazu, Gläubigern bei der Durchsetzung ihrer Ansprüche zu helfen und Schuldner zur Begleichung ihrer Schulden zu bewegen.',
    10002326: 'Die Strafprozessordnung (StPO) ist ein österreichisches Gesetz, das die rechtlichen Grundlagen für strafrechtliche Ermittlungs- und Gerichtsverfahren festlegt. Sie regelt Verfahrensabläufe, Befugnisse der Strafverfolgungsbehörden und Rechte der Beschuldigten. Die StPO zielt darauf ab, ein faires und rechtsstaatliches Verfahren zu gewährleisten.',
    10001702: 'Das Unternehmensgesetzbuch (UGB) ist ein österreichisches Gesetz, das die rechtlichen Regelungen für Unternehmen festlegt. Es betrifft Aspekte wie Gründung, Organisation, Haftung, Buchführung und Jahresabschluss. Das UGB dient dazu, einen rechtlichen Rahmen für die Geschäftstätigkeit von Unternehmen zu schaffen und den Schutz von Gläubigern und Gesellschaftern sicherzustellen.',
    10004570: 'Das Einkommensteuergesetz (EStG) von 1988 ist ein österreichisches Gesetz, das die Besteuerung von Einkommen regelt. Es betrifft die Ermittlung des steuerpflichtigen Einkommens, die Berechnung der Einkommensteuer und Abzugsmöglichkeiten. Das ESTG umfasst Regelungen zu verschiedenen Einkunftsarten wie unselbständiger Tätigkeit, Kapitalvermögen und Vermietung.',
    10001699: 'Die Zivilprozessordnung (ZPO) ist ein österreichisches Gesetz, das die Regelungen für Zivilverfahren vor Gericht festlegt. Sie betrifft Klageverfahren, Beweisführung, Urteilsverkündung und Vollstreckung zivilrechtlicher Ansprüche. Die ZPO zielt darauf ab, einen fairen und effizienten Ablauf von Zivilprozessen zu gewährleisten.',
    10011384: 'Das Kraftfahrgesetz (KFG) von 1967 ist ein österreichisches Gesetz, das die Regelungen für den Straßenverkehr festlegt. Es betrifft die Zulassung von Fahrzeugen, Führerscheinregelungen, Verkehrsregeln und Haftpflichtversicherungen. Das KFG zielt darauf ab, die Sicherheit im Straßenverkehr zu gewährleisten und den ordnungsgemäßen Betrieb von Fahrzeugen zu regeln.',
    20011502: 'Das Allgemeine Sozialversicherungsgesetz (ASVG) ist ein österreichisches Gesetz, das die gesetzliche Kranken-, Unfall- und Pensionsversicherung regelt. Es betrifft Beitragspflicht, Leistungsansprüche und Verwaltungsvorschriften für Versicherte. Das ASVG zielt darauf ab, den sozialen Schutz und die Absicherung der Arbeitnehmer in Österreich sicherzustellen.',
    10001848: 'Das Urheberrechtsgesetz (UrhG) ist ein österreichisches Gesetz zum Schutz von geistigem Eigentum. Es betrifft literarische, musikalische, künstlerische und softwarebezogene Werke. Das UrhG regelt die Rechte der Urheber und die Nutzung ihrer Werke. Es dient dem Schutz des geistigen Eigentums und der Förderung kultureller Vielfalt.',
    10003940: 'Die Bundesabgabenordnung (BAO) ist ein österreichisches Gesetz, das das Abgabenrecht regelt. Es betrifft die Festsetzung, Erhebung und Einbringung von Steuern und anderen Abgaben. Die BAO enthält Bestimmungen zum Abgabenverfahren, zur Abgabenerklärung und zur Abgabenkontrolle. Sie dient der ordnungsgemäßen Verwaltung und Durchsetzung der Abgaben.',
    10008840: 'Das Arbeitsverfassungsgesetz (ArbVG) regelt die Rechte und Pflichten von Arbeitnehmern und Arbeitgebern in Österreich. Es umfasst Themen wie Mitbestimmung, Betriebsräte, Arbeitszeit, Urlaub und Kündigungsschutz. Das Ziel des ArbVG ist ein ausgewogenes Verhältnis zwischen Arbeitnehmern und Arbeitgebern sowie der Schutz der Arbeitnehmerrechte. Es bildet die Grundlage für die Arbeitsbeziehungen in Österreich.',
    10008163: 'Das Gehaltsgesetz (GehG) regelt die Vergütung von Arbeitnehmern in Österreich. Es legt Mindestlöhne, Tarifverträge und Entgeltbestandteile wie Zulagen fest. Das Ziel des GehG ist faire Bezahlung und der Schutz der Arbeitnehmerinteressen. Es bildet die Grundlage für gerechte Entlohnung in Österreich.',
    10008872: 'Das Arbeitsvertragsrechts-Anpassungsgesetz (AVRAG) regelt die Rechte und Pflichten von Arbeitgebern und Arbeitnehmern in Österreich. Es betrifft Arbeitsverträge, Arbeitszeit, Urlaub und Kündigungsschutz. Das AVG zielt darauf ab, faire Arbeitsbedingungen zu gewährleisten und den Schutz der Arbeitnehmerrechte sicherzustellen. Es bildet die Grundlage für das Arbeitsverhältnis in Österreich.',
    20003047: 'Das Außerstreitgesetz (AußStrG) regelt außergerichtliche Verfahren in Österreich. Es betrifft Streitigkeiten außerhalb der Zuständigkeit der ordentlichen Gerichte, wie etwa familienrechtliche oder erbschaftsrechtliche Angelegenheiten. Das AußStrG fördert die außergerichtliche Streitbeilegung und ermöglicht eine effiziente Klärung von Rechtsfragen. Es bietet einen Rahmen für schnelle und faire Lösungen außerhalb des Gerichtsverfahrens.',
    10009600: 'Das Schulunterrichtsgesetz (SchUG) ist ein österreichisches Gesetz, das den Schulunterricht in Österreich regelt. Es betrifft die Organisation der Schulen, die Rechte und Pflichten von Schülern, Lehrern und Eltern sowie die Inhalte und Ziele des Unterrichts. Das SchUG stellt sicher, dass der Schulunterricht strukturiert, qualitativ hochwertig und den Bedürfnissen der Schüler entspricht. Es trägt zur Förderung des Bildungsrechts und zur Gewährleistung einer angemessenen Bildung in Österreich bei.',
    10001720: 'Es handelt sich um ein österreichisches Bundesgesetz, das die rechtlichen Bestimmungen für GmbHs in Österreich regelt. Es umfasst Regelungen zur Gründung, Organisation, Geschäftsführung, Haftung und Kapitalstruktur von GmbHs. Das GmbHG dient dazu, den rechtlichen Rahmen für GmbHs in Österreich festzulegen und den Schutz der Interessen der Gesellschafter und Gläubiger zu gewährleisten.',
    10008115: 'Das Vertragsbedienstetengesetz (VBG) regelt die Arbeitsbedingungen und Pflichten von Vertragsbediensteten im österreichischen öffentlichen Dienst. Es betrifft Einstellung, Beförderung, Dienstverhältnisende und stellt sicher, dass die Rechte und Pflichten der Vertragsbediensteten gewahrt werden. Es trägt zur effizienten Verwaltung des öffentlichen Dienstes bei.',
    20001921: 'Das Wohnungseigentumsgesetz (WEG) von 2002 ist ein österreichisches Gesetz, das die rechtlichen Bestimmungen für das Wohnungseigentum regelt. Es betrifft Eigentumsrechte, Verwaltung, Beschlüsse, Instandhaltung und Streitbeilegung. Das WEG zielt darauf ab, klare Regeln für Wohnungseigentümer zu schaffen und ein harmonisches Zusammenleben in Wohnanlagen zu gewährleisten.',
    10005770: 'Das Verwaltungsstrafgesetz (VStG) ist ein österreichisches Gesetz, das die Bestimmungen für die Verhängung von Verwaltungsstrafen regelt. Es legt fest, wie Verwaltungsübertretungen behandelt werden, einschließlich Zuständigkeiten, Verfahrensabläufen und Rechtsmitteln. Das VStG hat das Ziel, die Einhaltung behördlicher Vorschriften sicherzustellen und Verstöße angemessen zu ahnden.',
    20001474: 'Das Kinderbetreuungsgeldgesetz (KBGG) von 2023 regelt das Kinderbetreuungsgeld in Österreich. Es betrifft Anspruchsvoraussetzungen, Zahlungshöhe, -dauer und weitere Unterstützungsleistungen für Eltern. Das KBGG zielt darauf ab, Familien bei der Vereinbarkeit von Beruf und Familie zu unterstützen und finanzielle Sicherheit während der Betreuungszeit zu gewährleisten. Es fördert auch die frühkindliche Entwicklung.',
    10002462: 'Das Konsumentenschutzgesetz (KSchG) schützt Verbraucher in Österreich. Es regelt Vertragsabschlüsse, Gewährleistung und bekämpft unlautere Geschäftspraktiken. Das Ziel des KSchG ist der Schutz der Verbraucherrechte und ein faires Verhältnis zwischen Verbrauchern und Unternehmen. Es enthält Mechanismen zur Durchsetzung der Verbraucherrechte.',
  },
  courts: {
    ogh: 'Der Oberste Gerichtshof (OGH) ist das höchste Gericht in Österreich. Er entscheidet in Zivil-, Straf- und Verwaltungssachen und sorgt für einheitliche Rechtsprechung. Seine bindenden Entscheidungen müssen von allen niedrigeren Gerichten befolgt werden, wodurch Gerechtigkeit und Rechtsstaatlichkeit gewährleistet werden. Der OGH trägt zur Auslegung und Anwendung des österreichischen Rechts bei.',
    'ausl-egmr': 'Der Europäische Gerichtshof für Menschenrechte (EGMR) ist ein internationaler Gerichtshof mit Sitz in Straßburg. Er entscheidet über Beschwerden von Einzelpersonen, Organisationen und Staaten bezüglich Verletzungen der Europäischen Menschenrechtskonvention (EMRK). Der EGMR überprüft, ob die Rechte und Freiheiten gemäß der EMRK verletzt wurden. Individuen können eine Beschwerde einreichen, nachdem sie alle innerstaatlichen Rechtsmittel erschöpft haben. Die Urteile des EGMR sind bindend und tragen zur Förderung und zum Schutz der Menschenrechte in Europa bei.',
    'olg-wien': 'Das OLG Wien ist ein Gericht, das über Berufungen in Zivil- und Strafsachen entscheidet. Es überprüft Urteile der unteren Gerichte in Wien und Niederösterreich. Das OLG Wien trägt dazu bei, dass eine einheitliche Rechtsprechung gewährleistet wird und dass Fehlentscheidungen überprüft werden können.',
    'ausl-bgh': 'Der Bundesgerichtshof (BGH) ist das höchste Gericht in Deutschland für Zivil- und Strafsachen. Er überprüft Urteile anderer Gerichte und stellt sicher, dass das geltende Recht korrekt angewendet wird. Seine Entscheidungen sind für alle anderen Gerichte in Deutschland verbindlich. Der BGH trägt zur einheitlichen Auslegung des Rechts bei und spielt eine wichtige Rolle für die Gerechtigkeit im deutschen Rechtssystem.',
    'ausl-bag': 'Das Bundesarbeitsgericht (BAG) ist das höchste deutsche Gericht für Arbeitsrecht. Es ist zuständig für die Entscheidung von Rechtsstreitigkeiten zwischen Arbeitnehmern und Arbeitgebern. Das BAG überprüft Urteile der untergeordneten Arbeitsgerichte und stellt sicher, dass das Arbeitsrecht korrekt angewendet wird. Seine Entscheidungen haben eine wegweisende Bedeutung für die Arbeitsrechtsprechung in Deutschland. Das BAG trägt dazu bei, faire Arbeitsbedingungen und den Schutz der Rechte von Arbeitnehmern und Arbeitgebern zu gewährleisten.',
    rg: 'Das Reichsgericht war das oberste Gericht des Deutschen Kaiserreichs von 1879 bis 1945. Es hatte seinen Sitz in Leipzig und war für die Entscheidung in Zivil- und Strafsachen zuständig.',
    'ausl-ekmr': 'Die EKMR, auch bekannt als EMRK, ist ein völkerrechtlicher Vertrag zum Schutz der Menschenrechte in Europa. Sie legt grundlegende Rechte und Freiheiten fest. Der Europäische Gerichtshof für Menschenrechte überwacht die Einhaltung der Konvention durch die Mitgliedsstaaten und entscheidet über Beschwerden. Die EKMR spielt eine wichtige Rolle im Schutz der Menschenrechte in Europa.',
    opms: 'Der Oberste Patent- und Markensenat (OPMS oder OPM) ist ein spezialisierter Gerichtssenat in Österreich, der über Patent- und Markenrechtsfragen entscheidet. Seine Urteile haben maßgeblichen Einfluss auf die Rechtsprechung und schaffen Rechtssicherheit im Bereich Patente und Marken.',
  },
}
